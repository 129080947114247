.Case_Nav .box_Content .sub_Nav .navItem .secondary_Nav .secondaeyText:hover {
  color: #006AEB; }

.Case_Nav .box_Content .sub_Nav .navItem:hover a {
  color: #006AEB; }

.Case_Bg {
  margin: 0;
  padding: 0;
  position: fixed;
  height: 100%;
  left: 0;
  bottom: 0;
  overflow: hidden; }

.Case_Bg {
  width: 100%;
  background: #f5f9ff; }
  .Case_Bg .Img {
    width: 1200px;
    margin: 0 auto; }
    .Case_Bg .Img img {
      width: 1200px;
      height: 100%; }
  .Case_Bg .BgImgLeft {
    position: absolute;
    left: 0;
    z-index: -1; }
  .Case_Bg .BgImgRight {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1; }

.CaseBox {
  width: 100%; }
  .CaseBox .Case_Img {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 200; }
    .CaseBox .Case_Img .Img {
      width: 1200px; }
  .CaseBox .aaa {
    width: 100%;
    height: 300px; }
